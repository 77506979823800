@import 'globals';

$button-exit-duration: 1s;
$swipe-in-start: $button-exit-duration;
$swipe-in-duration: 0.8s;
$show-menu-start: calc(#{$swipe-in-start} + #{$swipe-in-duration});
$show-menu-duration: 1s;

$hide-menu-duration: 0.6s;
$swipe-out-start: $hide-menu-duration;
$swipe-out-duration: 0.6s;
$button-in-start: calc(#{$swipe-out-start} + #{$swipe-out-duration});
$button-in-duration: 0.6s;

$gutter: 5px;

//responsive variables
$hide-menu-duration-responsive: 0.1s;
$swipe-out-duration-responsive: 0.1s;
$button-in-start-responsive: calc(#{$hide-menu-duration-responsive} + #{$swipe-out-duration-responsive});

@media(min-width: 769px){
  .section-content {
    flex-wrap: wrap;
  }
  #patrimonio_back, #natureza_back {
    position: absolute;
    top: 50%;
    height: 25%;
    transform: translateY(-50%);
  }
}
#homepage-container {
  #header_menu{
    display:none;
  }
  &[data-selected="initial"] {
    .section-container {
      transform: translateX(-50%);
      transition: transform $swipe-out-duration $swipe-out-start ease-out;

      &::before {
        transition: filter $hide-menu-duration ease-out;
      }

      .section-content,
      #patrimonio_back, #natureza_back {
        opacity: 0;
        visibility: collapse;
        transition: opacity $hide-menu-duration ease-out,
          visibility 0s $hide-menu-duration ease-out;
      }
    }
    #main-logo,
    .section-btn-container {
      transition: opacity $button-in-duration $button-in-start ease-out,
      visibility 0s $button-in-start ease-out;
    }
  }
  &[data-selected="heritage"],
  &[data-selected="nature"] {
    .section-container {
      transition: transform $swipe-in-duration $swipe-in-start ease-out;

      &::before {
        filter: blur(2px);
        transition: filter $show-menu-duration $show-menu-start ease-out;
      }

      .section-content,
      #patrimonio_back, #natureza_back {
        opacity: 1;
        visibility: visible;
        transition: opacity $show-menu-duration $show-menu-start ease-out,
        visibility 0s $show-menu-start ease-out;
      }
    }
    #main-logo,
    .section-btn-container {
      visibility: collapse;
      opacity: 0;
      transition: opacity $button-exit-duration ease-out,
      visibility 0s $button-exit-duration ease-out;
    }
  }
  &[data-selected="heritage"] {
    .section-container {
      transform: none;
    }
  }
  &[data-selected="nature"] {
    .section-container {
      transform: translateX(-100%);
    }
  }
}

#homepage-container {
  width: 200vw;
  height: 100vh;
  display: flex;

  .section-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    #patrimonio_back {
      right: 0;
    }

    #natureza_back {
      left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-size: cover;
      background-position: center;
      // when blur is applied, a white border appears in the image. with scale, that margin is gone
      transform: scale(1.1);
    }

    .section-content {
      position: absolute;
      left: 50%;
      top: 20%;
      width: 62.5%;
      height: 60%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      .item-container {
        a {
          text-align: center;
          color: white;
        }
        .item {
          width: calc(100% - #{$gutter} * 2);
          height: calc(100% - #{$gutter} * 2);
          margin: $gutter;
          background-size: cover;
          background-position: center;
          background-image: url('/static/img/placeholder.jpg');
          display: flex;
          flex-direction: row;
          align-items: center;
          .item-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &.section-content-nature {
        .item-container {
          &.fauna-item,
          &.flora-item {
            width: 40%;
            height: 50%;
          }
          &.galeria-item,
          &.infografia-item,
          &.mapa-item {
            width: 20%;
            height: 33.33%;
          }
          &.percursos-item,
          &.dark-sky-item {
            width: 40%;
            height: 50%;
          }

          svg {
            max-width: 50px;
            max-height: 50px;
          }

        }
      }
      &.section-content-heritage {
        .item-container {
          &.visitar-item,
          &.percursos-item {
            width: 50%;
            height: 50%;
          }
          &.comer-item,
          &.galeria-item,
          &.dormir-item,
          &.mapa-item {
            width: 25%;
            height: 50%;
          }

          svg {
            max-width: 50px;
            max-height: 50px;
          }

        }

      }
    }

    .section-btn-container {
      height: 50%;
      width: 25%;
      bottom: 0;
      position: absolute;

      svg {
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      .section-btn {
        cursor: pointer;
      }

      &.section-btn-container-heritage {
        left: 50%;
      }

      &.section-btn-container-nature {
        right: 50%;
      }
    }
  }
  #main-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 25%;
    transform: translate(-50%, -50%);
  }
  .section-mobile{
    display: none;
  }
}

@media (max-width: 769px) and (max-height: 550px){
  .item-container{
    height: 65% !important;
  }
}

@media(max-width: 769px){
@media (orientation: portrait) {
  #homepage-container {
    .section-desktop{
      display: none !important;
    }
    .section-mobile{
      display: initial !important;
    }

    #back_btn_mobile{
      bottom: 0;
      position: fixed;
      z-index: 1;
    }
  }

  #homepage-container {
    &[data-selected="initial"] {
      .section-container {
        transition: initial;
        .section-content,
        #patrimonio_back, #natureza_back {
          transition: initial;
        }
      }
      #main-logo,
      .section-btn-container {
        transition: opacity $button-in-duration $button-in-start-responsive ease-out,
          visibility 0s $button-in-start-responsive ease-out;
      }
    }

    flex-direction: column;
    #main-logo {
      height: 35%;
      top: 50vh;
    }
    #heritage-container{
      width: 100%;
      overflow-y: hidden;
      .section-content{
        width: 100%;
        height: 75%;
        overflow-y: visible;
        padding: 0 25px;
        .mapa-item{
          margin-bottom: 20px;
        }
        .item-container{
          width: 100%;
          height: 35%;
          .item-content strong{
            font-size: 25px;
          }
          svg{
            max-width: 50px;
            max-height: 50px;
          }
        }
      }
      .section-btn-container{
        top: 0;
        svg{
          top: 0;
        }
      }
    }
    #nature-container{
      width: 100%;
      overflow-y: hidden;
      .section-content{
        width: 100%;
        height: 75%;
        overflow-y: visible;
        padding: 0 25px;
        .dark-sky-item{
          margin-bottom: 20px;
        }
        .item-container{
          width: 100%;
          height: 35%;
          .item-content strong{
            font-size: 25px;
          }
          svg{
            max-width: 50px;
            max-height: 50px;
          }
        }
      }
      .section-btn-container-nature{
        right: 0;
        height: 100%;
        svg{
          bottom: 0;
        }
      }
    }

    &[data-selected="heritage"],
    &[data-selected="nature"] {
      .section-container {
        transition: initial;

        &::before {
          filter: blur(2px);
          transition: initial;
        }

        .section-content,
        #patrimonio_back, #natureza_back {
          opacity: 1;
          visibility: visible;
          transition: opacity $button-exit-duration ease-out;
        }
        #patrimonio_back, #natureza_back {
          position: absolute;
          bottom: 0;
          height: 15%;
        }
        #natureza_back, #patrimonio_back {
          z-index: 1;
        }
        #patrimonio_back {
          left: 0;
        }
      }
      #main-logo,
      .section-btn-container {
        visibility: collapse;
        opacity: 0;
        transition: opacity $button-exit-duration ease-out;
      }
    }

    &[data-selected="heritage"] {
      #nature-container {
          flex: 0;
          transform:none;
        &::before {
          background-image: none;
        }
      }
      #header_menu{
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: 100%;
        img{
          max-height: 20%;
        }
      }
      #heritage-container {
          flex: 1;
          transform:none;
          width: 50%;
          overflow-y: scroll;
      }
      .nature-back-btn{
        display: none;
      }
      .section-container {
        &::before {
          position: fixed;
        }
      }
    }
    &[data-selected="nature"] {
      #nature-container {
        flex: 1;
        transform:none;
        width: 50%;
        overflow-y: scroll;
      }
      #header_menu{
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: 100%;
        img{
          max-height: 20%;
        }
      }
      #heritage-container {
          flex: 0;
          transform:none;
      }
      .section-container {
        &::before {
          position: fixed;
        }
      }
    }

}
}

@media (orientation:landscape) {
  #homepage-container:not([data-selected="initial"]) {
    .section-desktop{
      display: none !important;
    }
    .section-mobile{
      display: initial !important;
    }

    #back_btn_mobile{
      bottom: 0;
      position: fixed;
      z-index: 1;
    }
  }

  #homepage-container {
    &[data-selected="initial"] {
      .section-container {
        transition: initial;
        .section-content,
        #patrimonio_back, #natureza_back {
          transition: initial;
        }
      }
      #main-logo,
      .section-btn-container {
        transition: opacity $button-in-duration $button-in-start-responsive ease-out,
          visibility 0s $button-in-start-responsive ease-out;
      }
    }

    &:not([data-selected="initial"]){
    flex-direction: column;
    #main-logo {
      height: 35%;
      top: 50vh;
    }
    #heritage-container{
      width: 100%;
      overflow-y: hidden;
      .section-content{
        width: 100%;
        height: 75%;
        overflow-y: visible;
        padding: 0 25px;
        .mapa-item{
          margin-bottom: 20px;
        }
        .item-container{
          width: 100%;
          height: 35%;
          .item-content strong{
            font-size: 25px;
          }
          svg{
            max-width: 50px;
            max-height: 50px;
          }
        }
      }
      .section-btn-container{
        top: 0;
        svg{
          top: 0;
        }
      }
    }
    #nature-container{
      width: 100%;
      overflow-y: hidden;
      .section-content{
        width: 100%;
        height: 75%;
        overflow-y: visible;
        padding: 0 25px;
        .dark-sky-item{
          margin-bottom: 20px;
        }
        .item-container{
          width: 100%;
          height: 35%;
          .item-content strong{
            font-size: 25px;
          }
          svg{
            max-width: 50px;
            max-height: 50px;
          }
        }
      }
      .section-btn-container-nature{
        right: 0;
        height: 100%;
        svg{
          bottom: 0;
        }
      }
    }

    &[data-selected="heritage"],
    &[data-selected="nature"] {
      .section-container {
        transition: initial;

        &::before {
          filter: blur(2px);
          transition: initial;
        }

        .section-content,
        #patrimonio_back, #natureza_back {
          opacity: 1;
          visibility: visible;
          transition: opacity $button-exit-duration ease-out;
        }
        #patrimonio_back, #natureza_back {
          position: absolute;
          bottom: 0;
          height: 15%;
        }
        #natureza_back, #patrimonio_back {
          z-index: 1;
        }
        #patrimonio_back {
          left: 0;
        }
      }
      #main-logo,
      .section-btn-container {
        visibility: collapse;
        opacity: 0;
        transition: opacity $button-exit-duration ease-out;
      }
    }

    &[data-selected="heritage"] {
      #nature-container {
          flex: 0;
          transform:none;
        &::before {
          background-image: none;
        }
      }
      #header_menu{
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: 100%;
        img{
          max-height: 20%;
        }
      }
      #heritage-container {
          flex: 1;
          transform:none;
          width: 50%;
          overflow-y: scroll;
      }
      .nature-back-btn{
        display: none;
      }
      .section-container {
        &::before {
          position: fixed;
        }
      }
    }
    &[data-selected="nature"] {
      #nature-container {
        flex: 1;
        transform:none;
        width: 50%;
        overflow-y: scroll;
      }
      #header_menu{
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: 100%;
        img{
          max-height: 20%;
        }
      }
      #heritage-container {
          flex: 0;
          transform:none;
      }
      .section-container {
        &::before {
          position: fixed;
        }
      }
    }
  }
}
}
}

@media (max-width: 1200px) and (min-width: 769px) {
    #homepage-container {
        .section-container {
            .section-content {
                width: 75%;
                height: 70%;
                top: 15%;
            }
            .section-desktop {
                #patrimonio_back, #natureza_back {
                    max-width: 10%;
                }
            }
        }
    }
}
